const messages = {
  logout: "Logout",
  "admin.chain2gates.new": "Carica file Chain2Gate",
  "menu.dashbomenu.users.listard": "Dashboard",
  "menu.users.list": "Utenti",
  "menu.users.show": "Utente",
  "menu.users.new": "Nuovo utente",
  "menu.users.edit": "Modifica utente",
  "menu.groups.new": "Nuovo gruppo",
  "menu.groups.list": "Gruppi",
  "menu.groups.edit": "Modifica gruppo",
  "menu.apiKeys.list": "API Keys",
  "menu.apiKeys.edit": "Modifica API Key",
  "menu.requests.association": "Richieste associazione",
  "menu.requests.admissibility": "Richieste ammissibilità",
  "menu.massiverequests.title": "Richieste Massive",
  "menu.massiverequests.new": "Nuova richiesta massiva",
  "menu.massiverequests.list": "Lista richieste",
  "menu.massiverequests.items": "Dettaglio richieste",
  "menu.massiverequests.pods": "Abilita POD",
  "menu.plugs.title": "Chain2Gate Plug",
  "menu.plugs.associate": "Associa POD",
  "menu.plugs.new": "Carica file tracciabilità",
  "menu.plugs.process": "In attesa",
  "menu.plugs.list": "Lista Plugs",
  "menu.plugs.show": "Dettaglio",
  "menu.juices.title": "Juice Meter",
  "menu.juices.new": "Carica file tracciabilità",
  "menu.juices.list": "Lista Juice Meter",
  "menu.juices.show": "Dettaglio",
  "menu.chain2gates.title": "Dispositivi consegnati",
  "menu.chain2gates.new": "Carica file tracciabilità",
  "menu.chain2gates.list": "Lista Dispositivi",
  "menu.chain2gates.show": "Dettaglio",
  "menu.engines.title": "Ariston Core Engine",
  "menu.engines.new": "Carica file tracciabilità",
  "menu.engines.list": "Lista Core Engine",
  "menu.engines.show": "Dettaglio",
  "menu.engines.requests": "Richieste",
  "menu.associations.list": "Associazioni POD",
  "menu.associations.show": "Associazione POD",
  "menu.associations.edit": "Aggiorna richiesta",
  "menu.associations.new": "Nuova richieste associazione POD",
  "menu.admissibility.list": "Ammissibilità POD",
  "menu.admissibility.edit": "Aggiorna richiesta",
  "menu.admissibility.show": "Stato richiesta ammissibilità",
  "menu.requests.tickets": "Tickets",
  "menu.ariston.requests": "Richieste web",
  "menu.ariston.request": "Richiesta web",
  "menu.enelxrequests": "Richieste singole",
  "menu.enelxrequests.new": "Nuova richiesta singola",
  "menu.enelxrequests.upload": "Carica richiesta massiva",
  "menu.pods.show": "Dettagli POD",
  "menu.pods.title": "POD",
  "menu.pods.list": "Lista POD",
  "menu.pods.idle": "Lista POD in attesa",
  "menu.pods.new": "Ricerca singola POD",
  "menu.pods.upload": "Carica file CSV",
  "menu.customers.edit": "Modifica cliente",
  "menu.customers.show": "Cliente",
  "menu.customers.list": "Clienti",
  "menu.customers.new": "Nuovo cliente",
  "routes.customers.list": "Clienti",
  "routes.customers.show": "Cliente",
  "menu.endusers.edit": "Modifica utente",
  "menu.endusers.show": "Utente",
  "menu.endusers.list": "Utenti",
  "menu.endusers.new": "Nuovo utente",
  "routes.endusers.list": "Utenti",
  "routes.endusers.show": "Utente",
  "routes.pods.list": "Lista POD",
  "routes.pods.show": "Dettagli POD",
  "routes.massiverequests": "Richieste",
  "routes.massiverequests.new": "Nuova Richiesta",
  "routes.massiverequests.show": "Dettaglio",
  "label.input.podList": "Inserisci la lista dei POD separati da ;",
  "label.file.input": "CSV File",
  "label.file.choose": "Scegli file CSV",
  "label.not_assigned": "non assegnato",
  "label.customer": "Cliente",
  "pod.placeholder.enter": "Inserisci un POD",
  "pod.message.enter": "Inserisci il POD da associare",
  "apiKey.fields.id": "API Key",
  "apiKey.fields.group": "Cliente",
  "apiKey.fields.updatedAt": "Ultima modifica",
  "apiKey.fields.createdAt": "Data creazione",
  "group.fields.name": "Nome",
  "group.fields.users": "Utenti",
  "group.fields.createdAt": "Creato il",
  "pod.fields.id": "POD",
  "pod.fields.name": "Nome",
  "pod.fields.email": "Email",
  "pod.fields.surname": "Cognome",
  "pod.fields.du": "MAC ADDRESS",
  "pod.fields.duModel": "Nome DU",
  "pod.fields.hwVersion": "Versione HW",
  "pod.fields.swVersion": "Versione SW",
  "pod.fields.hwVersionDU": "Versione HW DU",
  "pod.fields.swVersionDU": "Versione SW DU",
  "pod.fields.initKey": "Chiave di inizializzazione",
  "pod.fields.key": "Chiave di esercizio",
  "pod.fields.systemTitle": "System Title",
  "pod.fields.profileID": "ID Profilo",
  "pod.fields.type": "Tipo",
  "pod.fields.status": "Stato",
  "pod.fields.admissibilityStatus": "Stato Ammissibilità",
  "pod.fields.message": "Messaggio",
  "pod.fields.uvam": "UVAM attivo",
  "pod.fields.degreeOfService": "Grado di servizio",
  "pod.fields.openedAt": "Data apertura",
  "pod.fields.closedAt": "Data chiusura",
  "pod.fields.createdAt": "Creato il",
  "pod.fields.updatedAt": "Aggiornato il",
  "pod.labels.produzione": "POD di produzione",
  "pod.labels.produzione2": "2° POD di produzione",
  "pod.labels.m2": "POD di produzione",
  "pod.labels.m2B": "2° POD di produzione",
  "pod.labels.m2C": "3° POD di produzione",
  "pod.labels.scambio": "POD di scambio",
  "juice.fields.id": "ID",
  "juice.fields.serialNumberEnel": "Seriale Enel",
  "juice.fields.skuCode": "SKU",
  "juice.fields.eanCode": "EAN",
  "juice.fields.macCode": "Codice AMC",
  "juice.fields.owner": "Proprietario",
  "juice.fields.progId": "Program ID",
  "juice.fields.releaseMeter": "Release meter",
  "juice.fields.du": "DU",
  "juice.fields.key": "Chiave",
  "juice.fields.etichetta": "Etichetta",
  "juice.fields.manufacturer": "Produttore",
  "juice.fields.productionDate": "Data produzione",
  "juice.fields.deliveryDate": "Data spedizione",
  "juice.fields.productionWeek": "Settinana di produzione",
  "juice.fields.deliveryWeek": "Settimana di spedizione",
  "juice.fields.deliveryAddress": "Indirizzo spedizione",
  "juice.fields.po": "PO",
  "juice.fields.ddt": "DDT",
  "juice.fields.createdAt": "Creato il",
  "juice.fields.updatedAt": "Aggiornato il",
  "engine.fields.id": "Seriale",
  "engine.fields.duName": "Nome DU",
  "engine.fields.mac": "MAC",
  "engine.fields.m1": "POD",
  "engine.fields.m2": "POD di produzione",
  "engine.fields.m3": "2° POD di produzione",
  "engine.fields.hwVersion": "Versione HW",
  "engine.fields.swVersion": "Versione SW",
  "engine.fields.fwVersion": "Versione FW",
  "engine.fields.key": "Chiave di esercizio",
  "engine.fields.initKey": "Chiave di inizializzazione",
  "engine.fields.systemTitle": "System Title",
  "engine.fields.createdAt": "Creato il",
  "engine.fields.processedAt": "Processato il",
  "engine.fields.updatedAt": "Aggiornato il",
  "chain2gate.fields.group": "Cliente",
  "chain2gate.fields.id": "Seriale",
  "chain2gate.fields.duName": "Nome DU",
  "chain2gate.fields.m1Type": "Tipologia POD",
  "chain2gate.fields.mac": "MAC",
  "chain2gate.fields.m1": "POD",
  "chain2gate.fields.m2": "POD di produzione",
  "chain2gate.fields.m2B": "2° POD di produzione",
  "chain2gate.fields.m2C": "3° POD di produzione",
  "chain2gate.fields.hwVersion": "Versione HW",
  "chain2gate.fields.swVersion": "Versione SW",
  "chain2gate.fields.fwVersion": "Versione FW",
  "chain2gate.fields.loginKey": "Chiave di accesso",
  "chain2gate.fields.k2": "Chiave di esercizio",
  "chain2gate.fields.k1": "Chiave di inizializzazione",
  "chain2gate.fields.systemTitle": "System Title",
  "chain2gate.fields.note": "Note private",
  "chain2gate.fields.createdAt": "Creato il",
  "chain2gate.fields.processedAt": "Processato il",
  "chain2gate.fields.updatedAt": "Aggiornato il",
  "customer.fields.id": "ID cliente",
  "customer.fields.name": "Ragione Sociale",
  "customer.fields.address": "Indirizzo",
  "customer.fields.city": "Città",
  "customer.fields.zip": "CAP",
  "customer.fields.state": "Provincia",
  "customer.fields.country": "Paese",
  "customer.fields.createdAt": "Creato il",
  "customer.fields.updatedAt": "Aggiornato il",
  "enduser.fields.id": "ID cliente",
  "enduser.fields.firstName": "Nome",
  "enduser.fields.lastName": "Cognome",
  "enduser.fields.address": "Indirizzo",
  "enduser.fields.city": "Città",
  "enduser.fields.email": "Email",
  "enduser.fields.fiscalCode": "Codice Fiscale",
  "enduser.fields.zip": "CAP",
  "enduser.fields.state": "Provincia",
  "enduser.fields.country": "Paese",
  "enduser.fields.createdAt": "Creato il",
  "enduser.fields.updatedAt": "Aggiornato il",
  "massiverequest.fields.pods": "Lista POD",
  "massiverequest.fields.name": "Richiesta",
  "massiverequest.fields.toBeProcessed": "Da processare",
  "massiverequest.fields.processed": "Tentativi",
  "massiverequest.fields.progress": "Progresso",
  "massiverequest.fields.errors": "Errori",
  "massiverequest.fields.status": "Stato",
  "massiverequest.fields.createdAt": "Creata il",
  "massiverequest.fields.completedAt": "Completata il",
  "massiverequest.fields.updatedAt": "Aggiornata il",
  "massiverequest.messages.pods":
    "Inserisci la lista dei POD da attivare, uno per riga.",
  "request.fields.id": "ID",
  "request.fields.pod": "POD",
  "request.fields.name": "Richiesta",
  "request.fields.requestID": "ID richiesta",
  "request.fields.status": "Status",
  "request.fields.hwVersion": "Versione HW",
  "request.fields.swVersion": "Versione SW",
  "request.fields.systemTitle": "System Title",
  "request.fields.profileID": "ID Profilo",
  "request.fields.du": "MAC",
  "request.fields.duName": "Nome DU",
  "request.fields.initKey": "Chiave iniziale",
  "request.fields.key": "Chiave operativa",
  "request.fields.createdAt": "Creata il",
  "request.fields.processed": "Tentativi",
  "request.fields.processedAt": "Processata il",
  "request.fields.updatedAt": "Aggiornata il",
  "request.fields.completedAt": "Completata il",
  "aristonRequest.fields.serial": "Seriale",
  "aristonRequest.fields.status": "Status",
  "aristonRequest.fields.firstName": "Nome",
  "aristonRequest.fields.lastName": "Cognome",
  "aristonRequest.fields.ownerFirstName": "Nome Assegnatario",
  "aristonRequest.fields.ownerLastName": "Cognome Assegnatario",
  "aristonRequest.fields.message": "Messaggio",
  "aristonRequest.fields.email": "Email",
  "aristonRequest.fields.m1": "POD",
  "aristonRequest.fields.m2": "POD di produzione",
  "aristonRequest.fields.m3": "2° POD di produzione",
  "aristonRequest.fields.createdAt": "Creata il",
  "aristonRequest.fields.processedAt": "Processata il",
  "aristonRequest.fields.updatedAt": "Aggiornata il",
  "aristonRequest.fields.fiscalCode": "Codice Fiscale",
  "association.fields.contractSigned": "Contratto utente firmato",
  "association.fields.group": "Rivenditore",
  "association.fields.serial": "Seriale",
  "association.fields.status": "Status",
  "association.fields.message": "Messaggio",
  "association.fields.firstName": "Nome",
  "association.fields.lastName": "Cognome",
  "association.fields.email": "Email",
  "association.fields.pod": "POD",
  "association.fields.m2": "POD di produzione",
  "association.fields.m2B": "2° POD di produzione",
  "association.fields.m2C": "3° POD di produzione",
  "association.fields.fiscalCode": "Codice Fiscale",
  "association.fields.sendEmail":
    "Invia email di aggiornamento stato all'utente",
  "association.fields.createdAt": "Creata il",
  "association.fields.processedAt": "Processata il",
  "association.fields.updatedAt": "Aggiornata il",
  "association.fields.podType": "Tipologia POD",
  "association.fields.m2Type": "Tipologia 1° POD di produzione",
  "association.fields.m2BType": "Tipologia 2° POD di produzione",
  "association.fields.m2CType": "Tipologia 3° POD di produzione",
  "association.fields.userType": "Tipologia utente",

  "ticket.fields.contractSigned": "Contratto utente firmato",
  "ticket.fields.group": "Rivenditore",
  "ticket.fields.id": "ID",
  "ticket.fields.serial": "Seriale Chain2gate",
  "ticket.fields.status": "Status",
  "ticket.fields.message": "Messaggio",
  "ticket.fields.firstName": "Nome",
  "ticket.fields.lastName": "Cognome",
  "ticket.fields.email": "Email",
  "ticket.fields.m1": "POD",
  "ticket.fields.m2": "POD di produzione",
  "ticket.fields.m2B": "2° POD di produzione",
  "ticket.fields.m2C": "3° POD di produzione",
  "ticket.fields.type": "Tipo richiesta",
  "ticket.fields.fiscalCode": "Codice Fiscale",
  "ticket.fields.sendEmail": "Invia email di aggiornamento stato all'utente",
  "ticket.fields.createdAt": "Creato il",
  "ticket.fields.processedAt": "Processato il",
  "ticket.fields.updatedAt": "Aggiornata il",
  "ticket.fields.podType": "Tipologia POD",
  "ticket.fields.m2Type": "Tipologia 1° POD di produzione",
  "ticket.fields.m2BType": "Tipologia 2° POD di produzione",
  "ticket.fields.m2CType": "Tipologia 3° POD di produzione",
  "ticket.fields.userType": "Tipologia utente",
  "ticket.open": "Ticket aperto",
  "ticket.closed": "Ticket chiuso",
  "ticket.in_progress": "Ticket in lavorazione",
  "ticket.hw_problem": "Problema HW",
  "ticket.sw_problem": "Problema SW",
  "ticket.chain2": "Problema Chain2",
  "ticket.connection_problem": "Problema di connessione",
  "ticket.other": "Altro",
  "ticket.fields.description": "Descrizione",
  "ticket.fields.note": "Note",
  "ticket.fields.podWithProblem": "Problema su POD",
  "admissibility.fields.group": "Rivenditore",
  "admissibility.fields.message": "Messaggio",
  "admissibility.fields.fiscalCode": "Codice Fiscale",
  "admissibility.fields.pod": "POD",
  "admissibility.fields.status": "Status",
  "admissibility.fields.createdAt": "Creata il",
  "admissibility.fields.closedAt": "Chiusa il",
  "admissibility.fields.updatedAt": "Aggiornata il",
  "admissibility.status.refused": "Rifiutata",
  "admissibility.status.pending": "Da processare",
  "admissibility.status.awaiting": "In lavorazione",
  "admissibility.status.admissible": "Ammissibile",
  "admissibility.status.not_admissible": "Non Ammissibile",
  "enelxRequest.fields.serial": "Seriale",
  "enelxRequest.fields.status": "Status",
  "enelxRequest.fields.firstName": "Nome",
  "enelxRequest.fields.lastName": "Cognome",
  "enelxRequest.fields.m1": "POD",
  "enelxRequest.fields.m2": "POD di produzione",
  "enelxRequest.fields.m3": "2° POD di produzione",
  "enelxRequest.fields.createdAt": "Creata il",
  "enelxRequest.fields.processedAt": "Processata il",
  "enelxRequest.fields.updatedAt": "Aggiornata il",
  "enelxRequest.status.error": "In errore",
  "enelxRequest.status.pending": "In attesa",
  "enelxRequest.status.processed": "Processata",
  "user.fields.username": "Username",
  "user.fields.email": "Email",
  "user.fields.verified": "Verificato",
  "user.fields.enabled": "Abilitato",
  "user.fields.groups": "Gruppi",
  "user.fields.group": "Gruppo",
  "user.fields.password": "Password temporanea",
  "user.fields.status": "Status",
  "user.fields.isAdmin": "Aggiungilo nel gruppo degli amministratori",
  "user.fields.createdAt": "Creato il",
  "user.fields.updatedAt": "Ultimo aggiornamento",
  "plug.fields.id": "ID",
  "plug.fields.pod": "POD",
  "plug.fields.code": "Codice MAC",
  "plug.fields.loginKey": "Chiave Login",
  "plug.fields.du": "DU",
  "plug.fields.duName": "Nome DU",
  "plug.fields.fwVersion": "Versione FW",
  "plug.fields.swVersion": "Versione SW",
  "plug.fields.initKey": "Chiave iniziale",
  "plug.fields.key": "Chiave operativa",
  "plug.fields.customer": "Cliente",
  "plug.fields.systemTitle": "Sistema",
  "plug.fields.createdAt": "Creato il",
  "plug.fields.updatedAt": "Aggiornato il",
  "plug.fields.toBeProcessed": "Da processare",
  "plug.fields.processedAt": "Processato il",
  "plug.message.pod.successful": "POD associato correttamente",
  "plug.buttons.process": "Processa i POD in attesa",
  "errors.pod.length": "Il POD deve essere di 11 caratteri",
  "messages.unassociated": "Non associato",
  "messages.success": "Success",
  "buttons.delete": "Cancella",
  "buttons.revoke": "Revoca",
  "buttons.remove": "Rimuovi",
  "buttons.cancel": "Annulla",
  "buttons.undo": "Annulla",
  "buttons.signout": "Esci",
  "buttons.add": "Aggiungi",
  "buttons.create": "Crea",
  "buttons.edit": "Modifica",
  "buttons.enable": "Abilita",
  "buttons.disable": "Disablita",
  "buttons.reload": "Refresh",
  "buttons.save": "Salva",
  "buttons.search": "Cerca",
  "buttons.lightMode": "Tema chiaro",
  "buttons.darkMode": "Tema scuro",
  "buttons.reprocess": "Riprocessa",
  "buttons.submitIdlePods": "Invia richiesta",
  "buttons.submit.requests": "Invia richieste in attesa",
  "options.pending": "In attesa di elaborazione",
  "options.awaiting": "In lavorazione",
  "options.admissible": "Ammissibile",
  "options.associated": "Associato",
  "options.not_admissible": "Non Ammissibile",
  "options.not_associated": "Non Associato",
  "options.already_associated": "Già Associato",
  "options.refused": "Richiesta rifiutata",
  "options.notInAlarm": "OK",
  "options.inAlarm": "In Allarme",
  "options.online": "Online",
  "options.offline": "Offline",
  "options.verified": "Verificato",
  "options.notVerified": "Non verificato",
  "options.enabled": "Abilitato",
  "options.notEnabled": "Non abilitato",
  "options.any": "Tutte",
  "options.ok": "OK",
  "options.ko": "KO",
  "options.alarm": "KO",
  "options.on": "Acceso",
  "options.off": "Spento",
  "options.yes": "SI",
  "options.no": "NO",
  "validation.required": "Obbligatorio",
  "validation.short": "Troppo corto",
  "validation.long": "Troppo lungo",
  "validation.invalid": "Valore non supportato",
  "labels.select": "Seleziona",
  "chain2gate.fields.__typename": "Tipologia",
};

export default messages;
